import { useEffect } from 'react';
import useAuth from 'contexts/auth/useAuth';
import { useNavigate, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';

const GuestGuard = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { isAuthenticated, isLoading } = useAuth();
    
    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            // Navigate to the originally requested path or dashboard
            const from = location.state?.from || '/dashboard';
            navigate(from, { replace: true });
        }
    }, [isAuthenticated, isLoading, navigate, location]);

    if (isLoading) {
        return <Loader />;
    }

    return !isAuthenticated ? children : null;
};

export default GuestGuard;