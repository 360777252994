import { useLayoutEffect, useEffect } from 'react'
import { createContext, useReducer } from 'react'
import axios from '../../../axios'
import { showSnackBar } from 'redux/actions/snackbarActions'
import { store } from 'redux/store'

const initialState = {
    isAuthenticated: false,
    isVerified: true,
    user_id: null,
    tenant_id: null,
    isLoading: true
}

const logoutChannel = new BroadcastChannel('logout_channel');

const setSession = (id, tenant_id) => {
    if (id && tenant_id) {
        window.localStorage.setItem('user_id', id)
        window.localStorage.setItem('tenant_id', tenant_id)
        // axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
    } else {
        window.localStorage.clear()
        // delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN': {
            const { isAuthenticated, user_id, tenant_id, isLoading } = action.payload
            return {
                ...state,
                isAuthenticated: isAuthenticated,
                user_id: user_id,
                tenant_id: tenant_id,
                isLoading: isLoading
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user_id: null,
                tenant_id: null,
                isLoading: false
            }
        }
        case 'SET_LOADING': {
            return {
                ...state,
                isLoading: action.payload
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => { },
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = (email, password) => {
        return new Promise((resolve, reject) => {
            axios.post('/auth/login', {
                email: email,
                password: password,
            })
                .then((res) => {
                    const user = res.data
                    window.localStorage.removeItem("logout")
                    setSession(user.id, user.tenant_id)
                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            isAuthenticated: true,
                            user_id: user.id,
                            tenant_id: user.tenant_id,
                            isLoading: false
                        }
                    })
                    resolve(user)
                })
                .catch((error) => {
                    store.dispatch(showSnackBar(error.response?.data?.message, "error"))
                    reject(error)
                })
        })
    }


    const logout = async () => {
        try {
            dispatch({ type: 'SET_LOADING', payload: true });
            const response = await axios.post('/auth/logout');
            setSession(null);
            window.localStorage.setItem("logout", Date.now());
            dispatch({ type: 'LOGOUT' });
            logoutChannel.postMessage('LOGOUT_EVENT');
            return response;
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Logout failed';
            store.dispatch(showSnackBar(errorMessage, "error"));
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };

    const handleLogout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        const handleLogoutMessage = (event) => {
            if (event.data === 'LOGOUT_EVENT') {
                handleLogout()
            }
        }

        logoutChannel.addEventListener('message', handleLogoutMessage)

        return () => {
            logoutChannel.removeEventListener('message', handleLogoutMessage)
            logoutChannel.close()
        }
    }, [])

    useLayoutEffect(() => {
        const fetchData = async () => {
            dispatch({ type: 'SET_LOADING', payload: true })

            try {
                const isLoginPage = window.location.pathname === '/'

                const logout = window.localStorage.getItem('logout')
                if (logout && !isLoginPage) {
                    handleLogout()
                    return
                }

                const user = await axios.get("/auth/refresh")
                const user_id = user.data.id
                const tenant_id = user.data.tenant_id

                if (user_id && tenant_id) {
                    setSession(user_id, tenant_id)
                    dispatch({
                        type: 'LOGIN',
                        payload: {
                            isAuthenticated: true,
                            user_id: user_id,
                            tenant_id: tenant_id
                        },
                    })
                } else {
                    handleLogout()
                }
            } catch (err) {
                // Only show error if we're not on the login page
                if (window.location.pathname !== '/') {
                    store.dispatch(showSnackBar(err.response?.data?.message || 'Authentication failed', "error"))
                }
                handleLogout()
            } finally {
                // Always set loading to false when done
                dispatch({ type: 'SET_LOADING', payload: false })
            }
        }

        fetchData()
    }, [])


    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext