import React, { createContext, useCallback, useState, useEffect } from "react";
import { getTenantIntegrations } from "redux/actions/integrationActions";
import { getAllSubTopics, getAllHeadTopics } from "redux/actions/topicActions";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";

export const FilterContext = createContext()

export const DEFAULT_FILTERS = {
    headtopics: [],
    subtopics: [],
    channels: [],
    types: [],
    sources: [],
    extracts: [],
    timeRange: 'last7days',
    fromDate: null,
    toDate: null,
    fixsearch: '',
    extractsearch: ''
}

export const FilterProvider = ({ children }) => {
    // Filter menu options state
    const [filterOptions, setFilterOptions] = useState({
        headtopics: [],
        subtopics: [],
        channels: [
            { id: "CALL", name: "Call" },
            { id: "REVIEW", name: "Review" },
            { id: "CHAT", name: "Chat" },
            { id: "EMAIL", name: "Email" }
        ],
        types: [
            { id: "COMPLAINT", name: "Complaint" },
            { id: "COMPLIMENT", name: "Compliment" },
            { id: "FEEDBACK", name: "Feedback" }
        ],
        sources: [],
    });

    // Filter selections state
    const [filters, setFilters] = useState(DEFAULT_FILTERS);

    //drafts filters
    const [draftFilters, setDraftFilters] = useState(DEFAULT_FILTERS);

    const [anchorEl, setAnchorEl] = useState(null);

    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        dispatch(getAllHeadTopics())
        dispatch(getAllSubTopics())
        dispatch(getTenantIntegrations())
    }, [])

    //selectors
    const {
        headtopics: headTopics,
        subtopics: subTopics
    } = useSelector(state => state.topics);
    const { tenantIntegrations: sources } = useSelector(state => state.integrations);

    useEffect(() => {
        if (headTopics) {
            setFilterOptions((options) => ({
                ...options,
                headtopics: headTopics
            }))
        }
    }, [headTopics])

    useEffect(() => {
        if (subTopics) {
            setFilterOptions((options) => ({
                ...options,
                subtopics: subTopics
            }))
        }
    }, [subTopics])

    useEffect(() => {
        if (sources) {
            setFilterOptions((options) => ({
                ...options,
                sources: sources
            }))
        }
    }, [sources])

    const handleRemovePill = (filterKey, itemId) => {
        const newFilterValue = filters[filterKey].filter(id => id !== itemId) || [];

        // Update both draft and applied filters simultaneously
        setDraftFilters(prev => ({
            ...prev,
            [filterKey]: newFilterValue
        }));

        setFilters(prev => ({
            ...prev,
            [filterKey]: newFilterValue
        }));
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;

        if (name === 'reset') {
            setFilters({
                ...DEFAULT_FILTERS,
                timeRange: filters.timeRange,
                fromDate: filters.fromDate,
                toDate: filters.toDate,
                extractsearch: filters.extractsearch,
                fixsearch: filters.fixsearch
            });

            setDraftFilters({
                ...DEFAULT_FILTERS,
                timeRange: filters.timeRange,
                fromDate: filters.fromDate,
                toDate: filters.toDate,
                extractsearch: filters.extractsearch,
                fixsearch: filters.fixsearch
            });
            return;
        }

        if (Array.isArray(DEFAULT_FILTERS[name])) {
            if (Array.isArray(value)) {
                setDraftFilters(prev => ({
                    ...prev,
                    [name]: value
                }));
            } else if (typeof value === 'string') {
                setDraftFilters(prev => {
                    const currentValues = prev[name] || [];
                    const valueExists = currentValues.includes(value);

                    return {
                        ...prev,
                        [name]: valueExists
                            ? currentValues.filter(v => v !== value)
                            : [...currentValues, value]
                    };
                });
            }
        } else {
            setDraftFilters(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };

    const handleOpenPopover = useCallback((event) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClosePopover = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleDateChange = {
        from: (newDate) => {
            setDraftFilters(prev => ({ ...prev, fromDate: newDate }));
        },
        to: (newDate) => {
            setDraftFilters(prev => ({ ...prev, toDate: newDate }));
        }
    };

    useEffect(() => {
        const data = { ...filters }
        setDraftFilters(data)
    }, [location.pathname])

    const handleTimeRangeChange = (event, newTimeRange) => {
        if (newTimeRange !== null) {
            setDraftFilters(prev => ({ ...prev, timeRange: newTimeRange }));
            if (newTimeRange !== 'custom') {
                setDraftFilters(prev => ({ ...prev, fromDate: null }));
                setDraftFilters(prev => ({ ...prev, toDate: null }));
            }
        }
    };

    const applyDraftFilters = () => {
        const data = { ...draftFilters }
        setFilters(data)
    };

    const open = Boolean(anchorEl);

    return (
        <FilterContext.Provider
            value={{
                filterOptions,
                filters,
                draftFilters,
                anchorEl,
                open,
                setDraftFilters,
                handleFilterChange,
                handleTimeRangeChange,
                handleOpenPopover,
                handleClosePopover,
                handleDateChange,
                applyDraftFilters,
                handleRemovePill
            }}
        >
            {children}
        </FilterContext.Provider>
    )
}