import axios from '../../../axios'
import { getExtract, HANDLESTATUSCHANGE } from './extractActions'
import { showSnackBar } from './snackbarActions'

export const GETALLFIXES = 'GETALLFIXES'
export const CREATENEWFIX = 'CREATENEWFIX'
export const GETEXTRACTASSOCIATEDFIX = 'GETEXTRACTASSOCIATEDFIX'
export const CLEANUPEXTRACTASSOCIATEDFIX = 'CLEANUPEXTRACTASSOCIATEDFIX'
export const CLEANUPALLFIXES = 'CLEANUPALLFIXES'
export const GETFIXASSOCIATEDEXTRACT = 'GETFIXASSOCIATEDEXTRACT'
export const CLEANUPFIXASSOCIATEDEXTRACT = 'CLEANUPFIXASSOCIATEDEXTRACT'
export const DELETEFIX = 'DELETEFIX'

export const getAllFixes = (filters) => {
    return (dispatch) => {
        axios.get(`/fixes/get`, {
            params: {
                ...filters,
                headtopics: filters.headtopics.length > 0 ? filters.headtopics.join(',') : undefined,
            }
        }).then((res) => {
            dispatch({
                type: GETALLFIXES,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const getFixAssociatedExtract = (fix_id) => {
    return (dispatch) => {
        axios.get(`/fixes/fix-extract/${fix_id}`).then((res) => {
            dispatch({
                type: GETFIXASSOCIATEDEXTRACT,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}


export const createNewFix = (headtopic_id, extract_id, body) => {
    return (dispatch) => {
        axios.post(`/fixes/create/headtopic/${headtopic_id}/extract/${extract_id}`, body).then((res) => {
            dispatch(getExtractAssociatedFixes(extract_id))
            dispatch(getAllFixes(headtopic_id))
            dispatch(getExtract(extract_id))
            dispatch(showSnackBar("The fix has been successfully assigned to the concern.", "success"))
        }).catch((error) => {
            dispatch(showSnackBar(error.response.data.error, 'error'))
            console.log(error)
        })
    }
}


export const patchExtractToFix = (extract_id, fix_id, body) => {
    return (dispatch) => {
        axios.post(`/fixes/patch/extract/${extract_id}/fix/${fix_id}`, body).then((res) => {
            dispatch(getExtractAssociatedFixes(extract_id))
            dispatch(getExtract(extract_id))
            dispatch(showSnackBar("The fix has been successfully assigned to the concern.", "success"))
        }).catch((error) => {
            dispatch(showSnackBar(error.response.data.error, 'error'))
            console.log(error)
        })
    }
}


export const getExtractAssociatedFixes = (extract_id) => {
    return (dispatch) => {
        axios.get(`/fixes/extract-fix/${extract_id}`).then((res) => {
            dispatch({
                type: GETEXTRACTASSOCIATEDFIX,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const deleteExtractAssociatedFixes = (extract_id, fix_id) => {
    return (dispatch) => {
        axios.delete(`/fixes/extract-fix/delete/${fix_id}`).then((res) => {
            dispatch(getExtractAssociatedFixes(extract_id))
            // dispatch({
            //     type: HANDLESTATUSCHANGE,
            //     payload: res.data
            // })
            dispatch(showSnackBar(res.data.message, "success"))
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const deleteFix = (id, confirmed = false) => {
    return (dispatch) => {
        axios.delete(`/fixes/${id}`, {
            params: confirmed ? { confirmed: true } : {}
        }).then((res) => {
            dispatch({
                type: DELETEFIX,
                payload: res.data
            })
            if (res.data?.success) {
                dispatch(showSnackBar(res.data.message, "success"))
            }
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const updateExtractAssociatedFixes = (extract_id, fix_id, body) => {
    return (dispatch) => {
        axios.patch(`/fixes/extract-fix/patch/${fix_id}`, body).then((res) => {
            dispatch(getExtractAssociatedFixes(extract_id))
            dispatch(showSnackBar(res.data.message, "success"))
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const changeStatusExtractAssociatedFixes = (extract_id, fix_id, newStatus) => {
    const url = newStatus === false ? `/fixes/extract-fix/inactive/${fix_id}` : `/fixes/extract-fix/active/${fix_id}`

    return (dispatch) => {
        axios.patch(url).then((res) => {
            dispatch(getExtractAssociatedFixes(extract_id))
            dispatch(getExtract(extract_id))
            dispatch(showSnackBar(res.data.message, "success"))
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const cleanUpExtractAssociatedFixes = () => {
    return {
        type: CLEANUPEXTRACTASSOCIATEDFIX
    }
}

export const cleanUpAllFixes = () => {
    return {
        type: CLEANUPALLFIXES
    }
}

export const cleanUpFixAssociatedExtract = () => {
    return {
        type: CLEANUPFIXASSOCIATEDEXTRACT
    }
}