import { RouterProvider } from 'react-router-dom';

// project import
import router from 'routes';
import ThemeCustomization from 'themes';

import ScrollTop from 'components/ScrollTop';
import { AuthProvider } from 'contexts/auth/authContext';
import { Provider } from 'react-redux';
import { store } from 'redux/store';
import SnackBar from 'components/snackbar/snackbar';
import { FilterProvider } from 'contexts/filters/filterContext';

// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //

export default function App() {
  return (
    <>
      <ThemeCustomization>
        <ScrollTop>
          <AuthProvider>
            <Provider store={store}>
              <SnackBar />
              <RouterProvider router={router} />
            </Provider>
          </AuthProvider>
        </ScrollTop>
      </ThemeCustomization>
    </>
  );
}
