import { produce } from "immer";
import {
    GETALLFIXES, GETEXTRACTASSOCIATEDFIX,
    CLEANUPEXTRACTASSOCIATEDFIX, CLEANUPALLFIXES,
    GETFIXASSOCIATEDEXTRACT,
    CLEANUPFIXASSOCIATEDEXTRACT,
    DELETEFIX
} from "redux/actions/fixActions";

const initialState = {
    allFixes: null,
    count: null,
    associatedFix: null,
    associatedExtract: null,
    deleteMessage: null
};

export const fixReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case GETALLFIXES:
                draft.allFixes = action.payload.fixes;
                draft.count = action.payload.count;
                break;
            case GETEXTRACTASSOCIATEDFIX:
                draft.associatedFix = action.payload;
                break;
            case GETFIXASSOCIATEDEXTRACT:
                draft.associatedExtract = action.payload;
                break;
            case CLEANUPEXTRACTASSOCIATEDFIX:
                draft.associatedFix = null;
                break;
            case CLEANUPALLFIXES:
                draft.allFixes = null;
                break;
            case CLEANUPFIXASSOCIATEDEXTRACT:
                draft.associatedExtract = null;
                draft.deleteMessage = null;
                break;
            case DELETEFIX: 
                draft.deleteMessage = action.payload;
                break;    
            default:
                break;
        }
    });
}
